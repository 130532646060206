<template>
    <section :class="{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile() }" class="h-auto">
        <div class="flex flex-col justify-center items-center pt-28 md:pt-36 lg:pt-40" style="font-family: Oxanium, sans-serif;">
            <div v-motion-pop-visible class="text-center text-white uppercase">
                <p class="text-4xl md:text-5xl lg:text-6xl font-bold">Slot Bounties</p>
            </div>
            <div class="hidden md:flex flex-row gap-2 mt-4">
                <button @click="selectedChallenge = 1" v-motion-pop-visible :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 1}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase leaderboard-btn">
                   Metaspins
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                  <!-- <span :class="{'border-[#1294F0]': selectedChallenge == 1}" class="absolute inset-y-0 left-0 -mb-[18px] bg-transparent border-r-2 border-white transform skew-x-[44deg] z-0 leaderboard-span"></span> -->
                </button>
                <!-- <button @click="selectedChallenge = 2" v-motion-pop-visible :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 2}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase leaderboard-btn">
                   Packdraw
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button>
                <button @click="selectedChallenge = 3" v-motion-slide-visible-left :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 3}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase clip-path-button-right leaderboard-btn">
                   Clash.GG
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                  <span :class="{'border-[#1294F0]': selectedChallenge == 3}" class="absolute inset-y-0 right-0 -mb-[18px] bg-transparent border-r-2 border-white transform skew-x-[135deg] z-0 leaderboard-span"></span>
                </button> -->
              </div>
              <div v-motion-pop-visible class="flex md:hidden flex-col gap-2 mt-10">
                <button @click="selectedChallenge = 1" :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 1}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase">
                    Metaspins
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button>
                <!-- <button @click="selectedChallenge = 2" :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 2}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase">
                    Packdraw
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button>
                <button @click="selectedChallenge = 3" :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 3}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase">
                    Clash.GG
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button> -->
              </div>
        </div>
        <div v-if="selectedChallenge == 1">
            <MetaspinsChallengesSection/>
        </div>
        <div v-if="selectedChallenge == 2">
            <PackdrawChallengesSection/>
        </div>
        <div v-if="selectedChallenge == 3">
            <ClashGGChallengesSection/>
        </div>
    </section>
</template>

<script>
import ClashGGChallengesSection from '@/sections/ClashGGChallengesSection.vue';
import MetaspinsChallengesSection from '@/sections/MetaspinsChallengesSection.vue';
import PackdrawChallengesSection from '@/sections/PackdrawChallengesSection.vue';
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
        MetaspinsChallengesSection,
        PackdrawChallengesSection,
        ClashGGChallengesSection,
    },
    data() {
        return {
            selectedChallenge: 1,
        }
    },
    methods: {
        isMobile() {
            return window.innerWidth < 1024;
        },
    }
})
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.leaderboard-btn:hover .leaderboard-span {
  border-color: #1294F0;
}

</style>
