<template>
    <section :class="{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile() }" class="h-auto">
        <div class="flex flex-col justify-center items-center pt-28 md:pt-36 lg:pt-40" style="font-family: Oxanium, sans-serif;">
            <div v-motion-pop-visible class="text-center text-white uppercase">
                <p class="text-4xl md:text-6xl lg:text-6xl font-bold">King of the Hill</p>
            </div>
            <div class="hidden lg:block">
            <div class="flex flex-col items-center gap-4 mt-6">
                  <div class="flex flex-row gap-4" style="font-family: Oxanium, sans-serif;">
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[80px] lg:h-[80px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ days }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-sm text-[#1294F0] uppercase">Days</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]">
                          <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ hours }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-sm text-[#1294F0] uppercase">Hours</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]">
                          <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ minutes }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-sm text-[#1294F0] uppercase">Minutes</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]">
                          <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ seconds }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-sm text-[#1294F0] uppercase">Seconds</span>
                      </div>
                  </div>
                  <div class="flex flex-col" style="font-family: Oxanium, sans-serif;">
                      <span class="text-base text-white text-center uppercase">all challenges are counted during the <br> inclusive dates:</span>
                      <span v-if="activeTab == 'current'" class="font-bold text-[#FC9A0E] text-center uppercase">{{ startDate }} - {{ endDate }} 12AM UTC</span>
                      <span v-else class="font-bold text-[#FC9A0E] text-center uppercase">{{ prevStartDate }} - {{ prevEndDate }} 12AM UTC</span>
                  </div>
              </div>
          </div>
          <div class="lg:hidden block my-5">
            <div class="flex flex-col items-center gap-4">
                  <div class="flex flex-row gap-4" style="font-family: Oxanium, sans-serif;">
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ days }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-xs text-[#1294F0] uppercase">Days</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ hours }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-xs text-[#1294F0] uppercase">Hours</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ minutes }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-xs text-[#1294F0] uppercase">Minutes</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ seconds }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-xs text-[#1294F0] uppercase">Seconds</span>
                      </div>
                  </div>
                  <div class="flex flex-col" style="font-family: Oxanium, sans-serif;">
                      <span class="text-base text-white text-center uppercase">all wagers are counted during the <br> inclusive dates:</span>
                      <span v-if="activeTab == 'current'" class="font-bold text-[#FC9A0E] text-center uppercase">{{ startDate }} - {{ endDate }} <br> 12AM UTC</span>
                      <span v-else class="font-bold text-[#FC9A0E] text-center uppercase">{{ prevStartDate }} - {{ prevEndDate }} <br> 12AM UTC</span>
                  </div>
              </div>
          </div>
          <div v-motion-pop-visible class="hidden lg:block w-[1200px] h-[350px] frame-bg mt-10">
                <div class="flex flex-row justify-start items-center">
                    <div class="flex flex-col items-start ml-14 gap-4 w-[50%] mt-4">
                        <div class="flex flex-row" style="font-family: Oxanium, sans-serif;">
                            <img src="../assets/ballyboy_logo.png" class="w-[210px]">
                        </div>
                        <div class="flex flex-col gap-4" style="font-family: Oxanium, sans-serif;">
                            <span class="text-4xl font-bold text-white text-left uppercase">How to Join Monthly King of the Hill?</span>
                            <ul class="list-disc text-white text-sm font-oxanium uppercase pl-5 space-y-1">
                                <li>CLICK THE BUTTON BELOW AND REGISTER ON METASPINS.COM NOW!</li>
                            </ul>
                            <router-link to="/metaspins-registration">
                                <button class="text-white bg-gradient-to-r from-[#165381] to-[#1294F0] font-bold py-4 w-[280px] relative uppercase transition ease-in-out hover:scale-110 duration-200">
                                    Register on Metaspins.com
                                    <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block lg:hidden mobile-frame-bg my-5 h-auto md:h-auto md:pb-5 mx-4 rounded-b-xl">
                <div class="flex flex-row justify-start items-center">
                    <div class="flex flex-col items-center gap-6">
                        <div class="flex flex-row mt-8" style="font-family: Oxanium, sans-serif;">
                            <img src="../assets/ballyboy_logo.png" class="w-[210px]">
                        </div>
                        <div class="flex flex-col justify-center gap-3" style="font-family: Oxanium, sans-serif;">
                            <span class="text-2xl font-bold text-white uppercase text-center px-2">How to Join Monthly King of the Hill?</span>
                            <ol class="lg:list-disc text-white text-sm font-oxanium uppercase px-10 lg:pl-5 space-y-1 text-center lg:text-left">
                                <li>CLICK THE BUTTON BELOW AND REGISTER ON METASPINS.COM NOW!</li>
                            </ol>
                            <div class="flex justify-center" style="font-family: Oxanium, sans-serif;">
                                <router-link to="/metaspins-registration">
                                    <button class="text-white bg-gradient-to-r from-[#165381] to-[#1294F0] font-bold py-4 w-[280px] relative uppercase transition ease-in-out hover:scale-110 duration-200">
                                        Register on Metaspins.com
                                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                    </button>
                                </router-link>
                            </div>
                        </div>
                        <div>
                            <img src="../assets/slot-frame-mobile-avatar.png" class="w-40 mb-4">
                        </div>
                    </div>
                    
                </div>
            </div>
            <div :class="{ 'bg-cover table-bg': loading && !isMobile(), 'bg-cover table-bg' : !loading && !isMobile(), 'bg-cover mobile-table-bg': loading && isMobile(), 'bg-cover mobile-table-bg' : !loading && isMobile()  }" class="flex flex-col justify-center items-center p-4 m-4 md:p-8 md:m-8 lg:w-[1200px] h-auto rounded-2xl">
              <div v-if="loading == true" class="text-center text-white text-2xl md:text-4xl mt-8 font-bold" style="font-family: Oxanium, sans-serif;">Loading Leaderboard...</div>
              <div v-if="loading == false" class="hidden md:flex flex-col lg:flex-row justify-between container mx-auto">
                <div class="flex flex-row justify-center items-center gap-6 lg:gap-28 mt-24 lg:ml-8" style="font-family: Oxanium, sans-serif;">
                    <div v-motion-pop-visible class="flex flex-col items-center w-60">
                        <div class="bg-[#222222] w-full lg:w-[300px] h-[280px] rounded-t-3xl">
                        <div class="flex flex-col justify-center items-center">
                            <div class="flex flex-col justify-between items-center -mt-28">
                                    <img src="../assets/gold-crown.png" class="w-[60px]">
                                    <!-- <img v-if="top3.length > 0 && top3[1] != undefined && top3[1].userDetails != undefined" :src="'https://cdn.discordapp.com/avatars/' +  top3[1].userDetails.discordId  + '/' +  top3[1].userDetails.avatar  + '.png'" class="w-[110px] rounded-full"/> -->
                                    <img src="../assets/avatar-1.png">
                                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                                    <span class="text-[#FFFFFF] font-bold">1</span>
                                    </div>
                                    <div>
                                    <span class="text-[#A8A8A8] font-bold text-sm">{{ top3.length > 0 && top3[0] != undefined ? top3[0].username : '---' }}</span>
                                    </div>
                                </div>
                                <div class="bg-black flex flex-col text-center w-full mt-5">
                                    <span class="text-white font-bold text-sm uppercase">Multiplier</span>
                                    <span class="text-white font-bold text-4xl uppercase">{{ top3.length > 0 && top3[0] != undefined ? top3[0].multi : '---' }}x</span>
                                    <span class="text-white font-bold text-sm uppercase"></span>
                                </div>
                                <div class="bg-black flex flex-col text-center w-full">
                                    <span class="text-white font-bold text-sm uppercase">Bet ID</span>
                                    <a :href="top3.length > 0 && top3[0] != undefined ? top3[0].betId : ''" target="_blank" class="flex justify-center items-center">
                                        <span class="text-[#1294F0] font-thin text-sm w-32 truncate">{{ top3.length > 0 && top3[0] != undefined ? top3[0].betId : '---' }}x</span>
                                    </a>
                                </div>
                                <div class="bg-gradient-to-tr from-[#8C421D] via-[#FBE67B] to-[#8C421D] flex flex-col text-center w-full mt-8 py-2">
                                    <span class="text-black font-bold text-4xl uppercase">{{ activeTab == 'current' ? getRewardValue(0) : getPrevRewardValue(0)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex items-center justify-center space-x-8 border-b border-gray-600 mt-14" style="font-family: Oxanium, sans-serif;">
                        <div 
                        class="cursor-pointer pb-2 relative text-white"
                        :class="{'text-blue-500': activeTab === 'current'}" 
                        @click="activeTab = 'current'; changeLeaderboard()"
                        >
                        CURRENT LEADERBOARD
                        <span 
                            v-if="activeTab === 'current'" 
                            class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
                        ></span>
                        </div>
                        
                        <div 
                        class="cursor-pointer pb-2 relative text-white"
                        :class="{'text-blue-500': activeTab === 'previous'}" 
                        @click="activeTab = 'previous'; changeLeaderboard()"
                        >
                        PREVIOUS LEADERBOARD
                        <span 
                            v-if="activeTab === 'previous'" 
                            class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
                        ></span>
                        </div>
                    </div>
                    <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Oxanium, sans-serif;">
                    <table class="w-full">
                        <thead v-motion-slide-visible-bottom>
                            <tr>
                            <th
                                class="md:pr-12 md:pb-10 lg:pr-14 lg:pb-10 text-white text-[18px] md:text-xl font-bold text-left">
                                Ranks
                            </th>
                            <th
                                class="md:px-10 md:pb-10 lg:px-14 lg:pb-10 text-white text-[18px] md:text-xl font-bold text-center">
                                Username
                            </th>
                            <th
                                class="md:px-10 md:pb-10 lg:px-14 lg:pb-10 text-white text-[18px] md:text-xl font-bold text-center">
                                Multi
                            </th>
                            <th
                                class="md:px-10 md:pb-10 lg:px-14 lg:pb-10 text-white text-[18px] md:text-xl font-bold text-center whitespace-nowrap">
                                Bet ID
                            </th>
                            <th
                                class="md:pl-12 md:pb-10 lg:pl-14 lg:pb-10 text-white text-[18px] md:text-xl font-bold text-right">
                                Prize
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="top5.length == 0">
                                <td colspan="5" class="text-center text-white font-bold py-4">No data to show</td>
                            </tr>
                            <tr v-motion-slide-visible-bottom v-for="(item, index) in top5" :key="index">
                                <td
                                class="md:pb-6 lg:pb-6 whitespace-nowrap text-[18px] text-white text-left uppercase tracking-widest">
                                    {{ getOrdinalSuffix(index + 2) }}
                                </td>
                                <td class="md:pb-6 lg:pb-6 whitespace-nowrap text-[18px] text-white text-center flex justify-center">
                                    <img src="../assets/avatar-1.png" class="w-6 h-6 rounded-full mr-2">
                                    {{ item.username }}
                                </td>
                                <td
                                    class="md:pb-6 lg:pb-6 whitespace-nowrap text-[18px] text-white text-center">
                                    <div class="flex flex-row justify-center items-center">
                                        <span>{{ item.multi }}x</span>
                                    </div>
                                </td>
                                <td
                                    class="md:pb-6 lg:pb-6 whitespace-nowrap text-[18px] text-white text-center">
                                    <a :href="item.betId" target="_blank" class="flex justify-center items-center">
                                        <span class="text-[#1294F0] w-32 truncate">{{ item.betId }}x</span>
                                    </a>
                                </td>
                                <td class="md:pb-6 lg:pb-6 whitespace-nowrap text-[18px] text-white text-right">
                                    <span>{{ activeTab == 'current' ? getRewardValue(index + 1) : getPrevRewardValue(index + 1) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
               </div>
             </div>
             <div v-if="loading == false" class="block md:hidden">
                <div class="flex items-center justify-center space-x-8 border-b border-gray-600 mt-6" style="font-family: Oxanium, sans-serif;">
                    <div 
                    class="cursor-pointer pb-2 relative text-white text-center"
                    :class="{'text-blue-500': activeTab === 'current'}" 
                    @click="activeTab = 'current'; changeLeaderboard()"
                    >
                    CURRENT LEADERBOARD
                    <span 
                        v-if="activeTab === 'current'" 
                        class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
                    ></span>
                    </div>
                    
                    <div 
                    class="cursor-pointer pb-2 relative text-white text-center"
                    :class="{'text-blue-500': activeTab === 'previous'}" 
                    @click="activeTab = 'previous'; changeLeaderboard()"
                    >
                    PREVIOUS LEADERBOARD
                    <span 
                        v-if="activeTab === 'previous'" 
                        class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
                    ></span>
                    </div>
                </div>
                <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Oxanium, sans-serif;">
                    <table class="w-full lg:w-auto">
                    <thead v-motion-slide-visible-bottom>
                        <tr>
                        <th
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-left">
                            Ranks
                        </th>
                        <th
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center">
                            User
                        </th>
                        <th
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center">
                            Multi
                        </th>
                        <th
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center">
                            Bet ID
                        </th>
                        <th
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-right">
                            Prize
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="top8.length == 0">
                        <td colspan="5" class="text-center text-white font-bold py-4 text-lg">No data to show</td>
                        </tr>
                        <tr v-motion-slide-visible-bottom v-for="(item, index) in top8" :key="index">
                        <td
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-semibold text-left">
                            {{ getOrdinalSuffix(index + 1) }}
                        </td>
                        <td class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[13px] text-white font-semibold text-center">
                            {{ item.username }}
                        </td>
                        <td
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[12px] text-white font-semibold text-center">
                            <div class="flex flex-row justify-center items-center">
                                <span>{{ item.multi }}x</span>
                            </div>
                        </td>
                        <td
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[12px] text-white font-semibold text-center">
                            <a :href="item.betId" target="_blank" class="flex justify-center items-center">
                                <span class="text-[#1294F0] w-16 truncate">{{ item.betId }}x</span>
                            </a>
                        </td>
                        <td
                            class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[12px] text-white font-semibold text-right">
                            <div class="flex flex-row justify-center items-center">
                                <span>{{ activeTab == 'current' ? getRewardValue(index) : getPrevRewardValue(index) }}</span>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
               </div>
               <div v-if="loading == false" class="flex flex-col justify-center mt-8 lg:mt-8 border-t border-gray-600 w-full container" style="font-family: Oxanium, sans-serif;">
                    <div @click="showTooltip2 = !showTooltip2" v-motion-pop-visible class="uppercase flex flex-col lg:flex-row items-center gap-1 mt-4 cursor-pointer mx-4">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1294F0" class="h-6 w-6">
                            <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                        </svg>
                    <span class="text-[#1294F0] text-sm md:text-lg">KOTH Rules</span>
                    </div>
                    <div v-if="showTooltip2">
                        <div v-motion-pop-visible class="flex flex-col items-start text-white mt-4 uppercase text-xs md:text-sm lg:text-base tracking-wider px-2 ">
                            <div>1. Use the Code "<span class="text-[#1294F0]">BallyBoy</span>".</div>
                            <div>2. Minimum Bet Requirement <span class="font-thin"> - Submit a Bet ID with at least $0.40 USD.</span>.</div>
                            <div>3. Achieve a Multi Above 500x <span class="font-thin"> - Only slot wins with 500x or higher are eligible</span>.</div>
                            <div>4. Must be registered via this link <span class="font-thin"> - SIGN UP HERE</span> <a href="https://metamedialinks.com/e36249d00" target="_blank"><span class="font-thin text-[#1294F0] underline">Registration link</span></a>.</div>
                            <div>5. Bet over $1.00 will receive additional prize money 💰</div>
                            <div>6. Submit Your Multi’s Bet ID Link <span class="font-thin"> - ACCESS THE FORM HERE</span> <a href="https://docs.google.com/forms/d/e/1FAIpQLSdh2Uz5W_xf8PYwhQrWlXRvwF1pHkuoX4ckz3hpagEmbyQWIw/viewform" target="_blank"><span class="font-thin text-[#1294F0] underline">Form link</span></a>.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-show="googleForm == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity w-screen h-screen" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto h-screen sm:h-auto" style="font-family: Oxanium, sans-serif;">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div v-motion-pop-visible class="relative overflow-hidden text-center h-screen sm:h-auto shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                    <div class="bg-[#222222] modal-clip-path px-2 pb-4 pt-5 sm:pt-6 sm:px-4 sm:pb-4 h-full sm:h-auto">
                    <div class="mt-3 text-center sm:mt-0 sm:text-center h-auto">
                        <div class="responsive-iframe-container">
                            <iframe
                                src="https://docs.google.com/forms/d/e/1FAIpQLSelMN5ZP81g6H5PaYwX-wPe45H_yUFhGwZPdnk-mQZBapDtCg/viewform?embedded=true"
                            ></iframe>
                        </div>
                    </div>
                    <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                        <button @click="googleForm = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase  main-btn">
                        Close
                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                        </button>
                    </div>
                    <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                        <button @click="googleForm = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-[130px] py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                        Close
                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
        
    },
    data() {
        return {
            activeTab: 'current',
            googleForm: false,
            top3: [],
            top5: [],
            top8: [],
            
            days: '',
            hours: '',
            minutes: '',
            seconds: '',

            currentLeaderboard: [],
            previousLeaderboard: [],
    
            startDate: null,
            endDate: null,
    
            prevStartDate: null,
            prevEndDate: null,

            notFormattedEndDate: null,

            loading: false,

            showTooltip2: true,

            leaderboardPrizes: JSON.parse(process.env.VUE_APP_PACKDRAW_LEADERBOARD_CURRENT_PRIZES)
        }
    },
    methods: {
        async init() {
            const baseUrl = process.env.VUE_APP_BASE_API;
            this.loading = true
            
            await axios.get(`${baseUrl}/api/leaderboard/slot-challenges`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
                this.currentLeaderboard = res.data.leaderboardData;
        
                this.startDate = moment(res.data.startDate).utc().format('MMMM DD, YYYY')
                this.endDate = moment(res.data.endDate).utc().format('MMMM DD, YYYY')
                
                this.notFormattedEndDate = res.data.endDate
            });

            await axios.get(`${baseUrl}/api/leaderboard/slot-challenges?previous=true`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(rest => {
                this.previousLeaderboard = rest.data.leaderboardData;

                this.prevStartDate = moment(rest.data.startDate).utc().format('MMMM DD, YYYY')
                this.prevEndDate = moment(rest.data.endDate).utc().format('MMMM DD, YYYY')
            });

            this.changeLeaderboard()
            this.startTimer();

            this.loading = false
        },
        changeLeaderboard(){
        if(this.activeTab == 'current'){
                const leaderboard = this.currentLeaderboard
                this.top3 = leaderboard.slice(0, 1).map((item) => {
                    return item;
                });
        
                this.top5 = leaderboard.slice(1, 5).map((item) => {
                    return item;
                });
        
                this.top8 = leaderboard.slice(0, 5).map((item) => {
                    return item;
                });
            }
            else{
                const leaderboard = this.previousLeaderboard
                this.top3 = leaderboard.slice(0, 1).map((item) => {
                    return item;
                });
        
                this.top5 = leaderboard.slice(1, 5).map((item) => {
                    return item;
                });
        
                this.top8 = leaderboard.slice(0, 5).map((item) => {
                    return item;
                });
            }
        },
        getOrdinalSuffix(n) {
            const s = ['th', 'st', 'nd', 'rd'],
            v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        },
        maskString(str) {
            if (!str || str.length < 2) {
            return str;
            }
            if (str.length <= 4) {
            const masked = '*'.repeat(str.length - 1);
            const last = str.slice(-1);
            return `${masked}${last}`;
            } else {
            const masked = '*'.repeat(4);
            const rest = str.slice(4);
            return `${masked}${rest}`;
            }
        },
        getRewardValue(rank) {
            const prizes = JSON.parse(process.env.VUE_APP_SLOT_LEADERBOARD_CURRENT_PRIZES);
            if(rank >= prizes.length){
                return 'X'
            }else{
                return '$ ' + prizes[rank];
            }
            
        },
        getPrevRewardValue(rank){
            const prizes = JSON.parse(process.env.VUE_APP_SLOT_LEADERBOARD_PREV_PRIZES);
            if(rank >= prizes.length){
                return 'X'
            }else{
                return '$ ' + prizes[rank];
            }
        },
        startTimer() {
        const getNextTargetDate = () => {
            // const now = moment.utc();
    
            let targetDate = moment.utc(this.notFormattedEndDate).subtract(1, 'seconds');
    
            // if (now.isAfter(targetDate)) {
            //   targetDate.add(1, 'week');
            // }
    
            return targetDate;
            };
    
            let targetDate = getNextTargetDate();
            let timerInterval;
    
            const updateTimer = () => {
            const nowUtc = moment.utc();
            const distance = targetDate.diff(nowUtc);
    
            if (distance < 0) {
                // this.init();
                clearInterval(timerInterval);
    
                // targetDate = getNextTargetDate();
                // this.startTimer();

                this.days = 0
                this.hours = 0
                this.minutes = 0
                this.seconds = 0
            } else {
                const duration = moment.duration(distance);
                this.days = Math.floor(duration.asDays());
                this.hours = duration.hours();
                this.minutes = duration.minutes();
                this.seconds = duration.seconds();
            }
            };
    
            updateTimer();
            timerInterval = setInterval(updateTimer, 1000);
        },
        isMobile() {
            return window.innerWidth < 1024;
        },
    },
    mounted() {
      this.init();
    },
})
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.frame-bg {
  background-image: url('../assets/slot-challenges-frame-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.mobile-frame-bg {
  background-image: url('../assets/slot-challenges-mobile-frame-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.table-bg {
  background-image: url('../assets/slot-challenges-table-bg.png');
  background-repeat: no-repeat;
}

.mobile-table-bg {
  background-image: url('../assets/slot-challenges-mobile-table-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
}

/* .cards-frame {
  background-image: url('../assets/cards-frame.png');
  background-repeat: no-repeat;
  background-size: cover;
} */

.modal-clip-path {
    clip-path: polygon(20px 0%, calc(100% - 20px) 0, 150% 100%, -50% 100%);
}

.responsive-iframe-container {
    position: relative;
    width: 100%; /* Full width */
    height: 100%;
    padding-top: 90%;
    overflow: hidden;
}

.responsive-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
</style>
