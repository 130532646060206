<template>
    <div>
      <!-- Navbar -->
      <nav :class="{'bg-gradient-to-l from-[#373f49]': !isScrolled && !isMobile(), 'bg-[#373f49]': isScrolled && !isMobile(), 'bg-[#373F49]': isMobile() }" class=" fixed w-full shadow-md z-50 px-6 md:px-10 2xl:px-40">
        <div class="container mx-auto flex justify-between items-center">
          <!-- Logo -->
          <div class="flex items-center pr-4">
            <img src="../assets/ballyboy_logo.png" alt="Bally Boy" class="w-48 md:w-[200px]" />
          </div>
  
          <!-- Menu Icon (Mobile) -->
          <div class="lg:hidden">
            <button @click.stop="toggleMobileMenu" class="text-white focus:outline-none mt-1">
              <svg v-motion-pop-visible v-show="mobileMenuOpen == false" xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
              <svg v-motion-pop-visible v-show="mobileMenuOpen == true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-7 w-7">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
  
          <!-- Links (Desktop) -->
          <div class="hidden lg:flex text-white uppercase" style="font-family: Oxanium, sans-serif;">
            <RouteLink
              to="/"
              icon="home.png"
              label="Home"
              :isActive="$route.path === '/'"
            />
            <!-- <RouteLink 
              to="/challenges"
              icon="flag.png"
              label="Challenges"
              :isActive="$route.path === '/challenges'"
            /> -->
            <RouteLink
              icon="crown.png"
              :isDropdown="true"
              :isRouter="false"
              :dropdownItems="[
                { label: 'KOTH', icon: 'slots.png', to: '/king-of-the-hill' },
                { label: 'Slot Bounties', icon: 'flag.png', to: '/slot-bounties' }
              ]"
              label="KOTH"
              :isActive="$route.path === '/king-of-the-hill' || $route.path === '/slot-bounties'"
            />
            <RouteLink 
              to="/leaderboards"
              icon="cup.png"
              label="Leaderboards"
              :isActive="$route.path === '/leaderboards'"
            />
            <RouteLink 
              to="/store"
              icon="shopping.png"
              label="Store"
              :isActive="$route.path === '/store'"
            />
            <RouteLink 
              to="/giveaways"
              icon="gift-nav.png"
              label="Giveaways"
              :isActive="$route.path === '/giveaways'"
            />
            <RouteLink v-if="$store.state.user != null"
              to="/profile"
              icon="user.png"
              label="Profile"
              :isActive="$route.path === '/profile'"
            />
            <button v-if="$store.state.user == null" @click="handleLogin()" class="ml-5 px-3 py-2 my-3.5 text-xs transition-all bg-[#1689DB] border border-[#1689DB] rounded-2xl shadow-2xl ease-in-out hover:scale-110 duration-200">
                <div class="flex flex-row justify-center items-center gap-2">
                    <svg svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 mb-0.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                    </svg>
                    <p class="tracking-widest font-bold uppercase">Login</p>
                </div>
            </button>
            <button v-if="$store.state.user != null"  @click="isLogout = true" class="ml-5 px-3 py-2 my-3.5 text-xs transition-all bg-[#1689DB] border border-[#1689DB] rounded-2xl shadow-2xl ease-in-out hover:scale-110 duration-200">
                <div class="flex flex-row justify-center items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 mb-0.5">
                    <path fill-rule="evenodd" d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6ZM5.78 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-1.72-1.72H15a.75.75 0 0 0 0-1.5H4.06l1.72-1.72a.75.75 0 0 0 0-1.06Z" clip-rule="evenodd" />
                  </svg>
                  <p class="tracking-widest font-bold uppercase">Logout</p>
                </div>
            </button>
          </div>
        </div>
      </nav>
      <!-- Sidebar Menu (Mobile) -->
      <transition name="slide">
        <div v-if="mobileMenuOpen" class="sidebar fixed bg-[#21272E] w-60 md:w-80 h-screen z-40 mt-[56px]" @click.stop>
          <!-- Links -->
          <div class="flex flex-col uppercase" style="font-family: Oxanium, sans-serif;">
            <router-link @click="mobileMenuOpen = false" to="/" class="text-white flex items-center py-5 hover:bg-[#D9D9D9] hover:bg-opacity-10" :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2 border-l-[#1689DB]': $route.path === '/' }">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <img src="../assets/home.png" alt="home">
                    <p class="tracking-widest font-bold text-xs">Home</p>
                </div>
            </router-link>
            <!-- <router-link @click="mobileMenuOpen = false"  to="/king-of-the-hill" class="text-white flex items-center py-5 hover:bg-[#D9D9D9] hover:bg-opacity-10" :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2 border-l-[#1689DB]': $route.path === '/king-of-the-hill' }">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <img src="../assets/flag.png" alt="flag" class="w-4 mb-0.5">
                    <p class="tracking-widest font-bold text-xs">King of the Hill</p>
                </div>
            </router-link> -->

            <div>
            <div 
              @click="toggleDropdown('kingOfTheHill')" 
              class="text-white flex items-center py-5 hover:bg-[#D9D9D9] hover:bg-opacity-10 cursor-pointer"
              :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2 border-l-[#1689DB]': $route.path === '/king-of-the-hill' || $route.path === '/slot-bounties'}"
            >
              <div class="flex flex-row justify-center items-center ml-4 gap-2">
                <img src="../assets/flag.png" alt="flag" class="w-4 mb-0.5">
                <p class="tracking-widest font-bold text-xs">King of the Hill</p>
                <span 
                  class="ml-auto mr-4 transform transition-transform"
                  :class="{ 'rotate-180': dropdowns.kingOfTheHill }"
                >
                <img class="ml-1" src="../assets/dropdown-arrow.png">
                </span>
              </div>
            </div>
            <transition name="slides">
              <div 
                v-if="dropdowns.kingOfTheHill" 
                class="ml-6 mt-1 text-xs font-bold text-white"
              >
                <router-link 
                  @click="mobileMenuOpen = false" 
                  to="/king-of-the-hill" 
                  class="block py-4 hover:bg-[#D9D9D9] hover:bg-opacity-10"
                  :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2': $route.path === '/king-of-the-hill'}"
                > 
                  <div class="flex flex-row justify-start items-center ml-4 gap-2">
                      <img src="../assets/slots.png" class="w-4 mb-0.5">
                      <p class="tracking-widest font-bold text-xs">KOTH</p>
                  </div>
                </router-link>
                <!-- <router-link 
                  @click="mobileMenuOpen = false" 
                  to="/koth-sports-bet" 
                  class="block py-4 hover:bg-[#D9D9D9] hover:bg-opacity-10"
                  :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2': $route.path === '/koth-sports-bet'}"
                >
                  <div class="flex flex-row justify-start items-center ml-4 gap-2">
                      <img src="../assets/like.png" class="w-4 mb-0.5">
                      <p class="tracking-widest font-bold text-xs">KOTH Sports Bet</p>
                  </div>
                </router-link> -->
                <router-link 
                  @click="mobileMenuOpen = false" 
                  to="/slot-bounties" 
                  class="block py-4 hover:bg-[#D9D9D9] hover:bg-opacity-10"
                  :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2': $route.path === '/slot-bounties'}"
                >
                  <div class="flex flex-row justify-start items-center ml-4 gap-2">
                      <img src="../assets/flag.png" class="w-4 mb-0.5">
                      <p class="tracking-widest font-bold text-xs">Slot Bounties</p>
                  </div>
                </router-link>
              </div>
            </transition>
          </div>
            <router-link @click="mobileMenuOpen = false"  to="/leaderboards" class="text-white flex items-center py-5 hover:bg-[#D9D9D9] hover:bg-opacity-10" :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2 border-l-[#1689DB]': $route.path === '/leaderboards' }">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <img src="../assets/cup.png" alt="cup">
                    <p class="tracking-widest font-bold text-xs">Leaderboard</p>
                </div>
            </router-link>
            <router-link @click="mobileMenuOpen = false"  to="/store" class="text-white flex items-center py-5 hover:bg-[#D9D9D9] hover:bg-opacity-10" :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2 border-l-[#1689DB]': $route.path === '/store' }">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <img src="../assets/shopping.png" alt="shopping">
                    <p class="tracking-widest font-bold text-xs">Store</p>
                </div>
            </router-link>
            <router-link @click="mobileMenuOpen = false"  to="/giveaways" class="text-white flex items-center py-5 hover:bg-[#D9D9D9] hover:bg-opacity-10" :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2 border-l-[#1689DB]': $route.path === '/giveaways' }">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <img src="../assets/gift-nav.png" alt="gift" class="w-4 mb-0.5">
                    <p class="tracking-widest font-bold text-xs">Giveaways</p>
                </div>
            </router-link>
            <router-link v-if="$store.state.user != null" @click="mobileMenuOpen = false"  to="/profile" class="text-white flex items-center py-5 hover:bg-[#D9D9D9] hover:bg-opacity-10" :class="{ 'bg-[#D9D9D9] bg-opacity-10 border-l-2 border-l-[#1689DB]': $route.path === '/profile' }">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <img src="../assets/user.png" alt="user" class="w-4 mb-0.5">
                    <p class="tracking-widest font-bold text-xs">Profile</p>
                </div>
            </router-link>
            <button v-if="$store.state.user == null" @click="handleLogin()" class="pl-4 py-5 text-xs hover:bg-[#D9D9D9] hover:bg-opacity-10 flex justify-start">
                <div class="flex flex-row justify-center items-center gap-2">
                    <img src="../assets/login.png" alt="login" class="w-4 mb-0.5">
                    <p class="tracking-widest font-bold uppercase text-white">Login</p>
                </div>
            </button>
            <button v-if="$store.state.user != null" @click="isLogout = true" class="pl-4 py-5 text-xs hover:bg-[#D9D9D9] hover:bg-opacity-10 flex justify-start">
                <div class="flex flex-row justify-center items-center gap-2">
                    <img src="../assets/logout.png" alt="logout" class="w-4 mb-0.5">
                    <p class="tracking-widest font-bold uppercase text-white">Logout</p>
                </div>
            </button>
          </div>
        </div>
      </transition>
    </div>
    <div v-show="isLogout == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
        <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                <div class="cards-frame px-4 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-center">
                    <h3 class="text-xl font-semibold leading-6 text-white uppercase" id="modal-title">Logout Ballyboy account</h3>
                    <div class="mt-2">
                        <p class="text-white">Are you sure you want to logout your account?</p>
                    </div>
                  </div>
                  <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                    <button @click="isLogout = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-left main-btn">
                      Cancel
                      <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                      <span class="absolute inset-y-0 left-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[35deg] z-0 main-span"></span>
                    </button>
                    <button @click="handleLogout()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-right main-btn">
                      Confirm
                      <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                      <span class="absolute inset-y-0 right-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[143deg] z-0 leaderboard-span main-span"></span>
                    </button>
                  </div>
                  <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                    <button @click="handleLogout()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-10 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                      Confirm
                      <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                    </button>
                    <button @click="isLogout = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                      Cancel
                      <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                    </button>
                  </div>
                </div>
            </div>
            </div>
        </div>
      </div>
</template>
  
<script>
import RouteLink from './RouteLink.vue';
  export default {
    emits: ['logout'],
    components: {
      RouteLink
    },
    data() {
      return {
        mobileMenuOpen: false,
        isScrolled: false,

        isLogout: false,

        dropdowns: {
          kingOfTheHill: false,
        },
      };
    },
    methods: {
      toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
      },
      handleClickOutside(event) {
        if (this.mobileMenuOpen && !event.target.closest('.sidebar')) {
          this.mobileMenuOpen = false;
        }
      },
      isMobile() {
        return window.innerWidth < 1024;
      },
      handleScroll() {
        this.isScrolled = window.scrollY > 0;

        // if (this.showMenu && window.innerWidth < 768) {
        //     this.isScrolled = true;
        // }
      },
      handleLogin() {
          const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`
          window.location.href = loginUrl
      },
      handleLogout(){
        this.$emit('logout')
      },
      toggleDropdown(menu) {
        if(this.dropdowns[menu] == true){
          this.dropdowns[menu] = false
        }
        else{
          this.dropdowns[menu] = true
        }
      },
    },
    mounted() {
      window.addEventListener('click', this.handleClickOutside);
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('click', this.handleClickOutside);
      window.removeEventListener('scroll', this.handleScroll);
    },
  };
</script>
  
<style scoped>
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter-from {
  transform: translateX(-100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}

.slides-enter-active, .slides-leave-active {
  transition: all 0.2s ease-in-out;
}
.slides-enter-from {
  transform: translateY(-10px);
  opacity: 0;
}
.slides-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.main-btn:hover .main-span {
  border-color: #1294F0;
}

.cards-frame {
  background-image: url('../assets/cards-frame.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
  